<template>
  <div class="footer">
      <div class="footer-top">
          <div class="center-box flex-row flex-web space-between">
              <div class="ft-left">
                  <ul class="ft-ul flex-row flex-wrap">
                      <li v-for="(item) in footer_cate" :key="item.id">
                          <router-link :to="{path:item.outlinkurl}">{{item.catname}}</router-link>
                      </li>
                  </ul>
                  <div class="ft-notice rel"><i class="iconfont icon-zhuyix"></i>DISCLAIMER: Registered names and trademarks are the copyright and property of their respective owners. Use of this Web site constitutes acceptance of the  Terms of use  and  Privacy Policy.</div>
                  <div class="g-box flex-row align-center">
                      <div class="g-item" v-for="item in footer_list" :key="item.id">
                          <a :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''"><img class="g-img" :src="item.img1" :alt="item.title"></a>
                      </div>
                  </div>
              </div>
              <div class="ft-right flex-column align-end right-mt20">
                  <div class="mimg-box">
                      <a class="m-link" :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''"  v-for="(item) in footer_list1" :key="item.id"><img class="m-img" :src="item.img1" :alt="item.title"></a>
                  </div>
                  <!-- <div class="mselect flex-row align-center">
                      <img class="cou-img mr10" src="../../public/img/en.png" alt="">
                      <select class="normal-select m-select">
                          <option value="English">English</option>
                      </select>
                  </div> -->
              </div>
          </div> 
      </div>
      <div class="footer-bott">
          <div class="center-box">
              <div class="footer-info" v-html="wab_info.copyright"></div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FooterBott',
  components: {},
  props: {
    
  },
  data () {
    return {
        footer_cate:[],
        footer_list:[],
        footer_list1:[],
        wab_info:{}
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    this.get_footer_cate()
    this.get_footer_list({ty:6})
    this.get_footer_list({ty:7})
    this.get_wab_info()
  },
  methods: {
    get_footer_cate(){
      this.$http.post("/api/index/get_footer_cate").then((res)=>{
          if (res.code == 200) {
            this.footer_cate = res.data
          }
      })
    },
    get_footer_list(data){
        this.$http.post("/api/index/get_footer_list",data).then((res)=>{
          if (res.code == 200) {
            if (data.ty == 6) {
                this.footer_list = res.data
            } else if (data.ty == 7) {
                this.footer_list1 = res.data
            }
          }
      })
    },
    get_wab_info(){
        this.$http.post("/api/common/get_wab_info").then((res)=>{
          if (res.code == 200) {
            this.wab_info = res.data
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
    .footer {background:#090723;color:rgba(255,255,255,0.5);font-size:12px}
    .g-img {max-height: 35px;}
    .m-img {width: 36px;}
    .ft-ul>li {font-size:14px;margin-right:30px;margin-bottom:16px;}
    .footer-top {padding:40px 0 30px}
    .ft-notice {padding-left:25px;line-height:1.6em}
    .ft-notice .icon-zhuyix {position:absolute;top:0;left:0}
    .ft-left {width:60%}
    .g-box {margin-top:20px}
    .g-item {margin-right:30px}
    .m-link {margin-left:15px;}
    .mselect {width:152px;height:38px;background-color:#222222;padding:0 20px;margin-top:30px;}
    .m-select {color:#fff;width:100%;flex:1;background: url(../../public/img/arrow-down-fill-white.png) no-repeat 96% center;}
    .footer-info {line-height:1.6em}
    .footer-bott {padding-bottom:30px;}
    @media (max-width:992px) {
        .ft-ul>li {margin-right: 15px;}
        .m-link {margin-left:0;margin-right:10px;}
        .ft-right {align-items:flex-start;}
        .mselect {margin-top:20px;}
        .footer-top {padding:30px 0 25px;}
        .g-item {margin-right:20px;}
    }
</style>
<style lang="scss">
.footer a {color:rgba(255,255,255,0.5)}
</style>
