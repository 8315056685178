import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "swiper/css/swiper.css";
import  "./assets/font/iconfont.css";
import $ from 'jquery'
import $http from './utils/http';

import { Pagination,InputNumber,Input,Radio,Form,FormItem,Checkbox,CheckboxGroup,Select,Option,Button,Upload,Message,MessageBox,Image,RadioGroup,DatePicker,Tooltip,Rate,Avatar} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

const originalPush = router.replace;
router.replace = function replace(location) {
    return originalPush.call(this, location).catch(err => err)
};



// 设置语言
locale.use(lang)

Vue.use(Pagination);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Upload);
Vue.use(Image);
Vue.use(DatePicker);
Vue.use(Tooltip);
Vue.use(Rate);
Vue.use(Avatar);

Vue.prototype.$http = $http
Vue.prototype.$ = $
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
