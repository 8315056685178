<template>
  <div class="home body-bj">
    <div class="banner rel">
      <div class="center-box flex-row flex-web space-between rel">
        <div class="banner-left">
          <div class="swiper-container mySwiper2">
              <div class="swiper-wrapper" >
                  <div class="swiper-slide" v-for="(item) in banner" :key="item.id">
                      <a :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''">
                        <img class="block banner-img" :src="item.img1" :alt="item.title">
                      </a>
                  </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-banner-prev"></div>
              <div class="swiper-button-next swiper-banner-next"></div>
          </div>
        </div>
        <div class="banner-right">
          <div thumbsSlider="" class="swiper-container mySwiper swiper-rbanner">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in banner" :key="item.id" @mouseover="hoverevent(index)">
                <a :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''">
                  <div class="flex-row flex-web">
                    <div class="br-left">
                      <img class="br-img block h-img" :src="item.img1" :alt="item.title">
                    </div>
                    <div class="br-right">
                      <div class="br-tit">{{item.title}}</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <!-- <ul class="br-ul hover-img">
            <li v-for="(item) in banner2" :key="item.id">
              <a :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''">
                <div class="flex-row">
                  <div class="br-left">
                    <img class="br-img block h-img" :src="item.img1" :alt="item.title">
                  </div>
                  <div class="br-right">
                    <div class="br-tit">{{item.title}}</div>
                  </div>
                </div>
              </a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    

    <div class="latest">
      <div class="center-box">
        <ul class="late-ul flex-row flex-wrap">
          <li >
            <div class="curr-top">
              <div class="curr-top-tit">Latest game</div>
            </div> 
            <div class="cum-bott">
              <ul class="cum-ul hover-bj">
                <li v-for="(item) in product_game_new" :key="item.id">
                  <router-link class="flex-row align-center" :to="{name:'tradding',params:{gid:item.id}}">
                    <img class="cub-img mr10" :src="item.pic" alt="item.pic">
                    <div class="cub-itit webkit line1">{{item.name}}</div>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="curr-top tc">
              <div class="curr-top-tit">Hot recommendation</div>
            </div>
            <div class="cum-bott cum-bpd">
              <ul class="cum-box-ul tc flex-row flex-wrap">
                <li v-for="(item) in product_game_hot" :key="item.id">
                  <router-link :to="{name:'tradding',params:{gid:item.id}}">
                    <div class="cum-btop">
                      <img class="cum-b-img" :src="item.pic" alt="item.pic">
                    </div>
                    <div class="cum-bbot">
                      <div class="cub-itit cub-itit2">{{item.name}}</div>
                      <!-- <div class="cub-inum" v-if="item.num">{{item.num}}</div> -->
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="curr-top">
              <div class="curr-top-tit">Q&A</div>
            </div>
            <div class="cum-bott">
              <ul class="cum-ul cum-ul2" v-for="(item) in question_index" :key="item.id">
                <li>
                  <router-link class="flex-row" :to="{path:'/helpdetails/' + item.id}">
                    <div class="cub-itit">{{item.title}}</div>
                  </router-link>
                </li>
              </ul>
              <div class="cum-more tc pt20">
                <router-link class="cum-more-btn" :to="{path:'/help'}">More frequently asked Questions</router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="currency">
      <div class="center-box">
        <ul class="curr-ul flex-row flex-wrap space-between">
          <li v-for="(item) in product_serve" :key="item.id" class="flex-column">
            <div class="curr-top" :style="{'background-image':'url(' + item.pic + ')'}">
              <div class="curr-top-tit">{{item.name}}</div>
            </div>
            <div class="curr-bott rel flex-column just-end flex1">
              <div class="curr-binner rel">
                <ul class="cub-ul">
                  <li v-for="(sonitem) in item.product_game" :key="sonitem.id">
                    <router-link class="flex-row align-center space-between" :to="{name:'tradding',params:{gid:sonitem.id,sid:item.id}}">
                      <div class="cub-left flex-row align-center">
                        <img class="cub-img mr20" :src="sonitem.pic" alt="sonitem.pic">
                        <div class="cub-itit webkit line1">{{sonitem.name}}</div>
                      </div>
                      <div class="cub-right">
                        <span class="cub-btn" v-if="sonitem.num">{{sonitem.num}}</span>
                      </div>
                    </router-link>
                  </li>
                </ul>
                <div class="tc cub-btn-box">
                  <router-link class="view-btn" :to="{name:'tradding',params:{sid:item.id}}">View More</router-link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="news_">
      <div class="center-box">
        <div class="news_tit_group flex-row align-center space-between">
          <div class="news_tit flex-row align-center">
            <img class="icon-img mr10" src="../../public/img/icon1.png" />
            <span>NEWS</span>
          </div>
          <router-link class="tit-more" :to="{path:'/news'}">View All  >></router-link>
        </div>

        <ul class="news_ul flex-row flex-wrap hover-tit">
          <li v-for="(item) in news" :key="item.id">
            <router-link :to="{path:'/newsdetails/' + item.id}">
              <div class="news_top">
                <img class="news-img block" :src="item.img1" :alt="item.title" />
              </div>
              <div class="news_bott">
                <div class="news-tit">{{item.title}}</div>
                <div class="news_time">Date : {{item.sendtime}}</div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="steps col-fff">
      <div class="center-box">
        <div class="step-tit tc">STEPS FOR ORDERING</div>

        <ul class="steps-ul flex-row flex-wrap">
          <li v-for="(item) in steps" :key="item.id">
            <div class="steps-top">
              <img class="step-img" :src="item.img1" :alt="item.title">
            </div>
            <div class="steps-bott">
              <div class="steps-tit">{{item.title}}</div>
              <div class="steps-desc">{{item.introduce}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="busi">
      <div class="center-box">
        <div class="news_tit_group">
          <div class="news_tit">
            <span>Payment method</span>
          </div>
        </div>

        <div class="busi-pd rel">
          <div class="swiper-container swiper-partner" id="swiper-partner" v-if="partner">
            <div class="swiper-wrapper" >
                <div class="swiper-slide" v-for="(item) in partner" :key="item.id">
                    <a :href="item.linkurl ? item.linkurl : 'javascript:void(0)'" :target="item.linkurl ? '_blank' : ''">
                      <img class="part-img" :src="item.img1" :alt="item.title">
                    </a>
                </div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev swiper-part-prev"></div>
          <div class="swiper-button-next swiper-part-next"></div>
        </div>
      </div>
    </div>

    <div class="index-mask" v-if="article_show">
      <div class="responsive-y index-mask-inner bgfff">
        <i class="iconfont icon-close" @click="article_show = false"></i>
        <div class="mask-desc" v-html="article_detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name: 'HomeView',
  data(){
    return {
      article_show:false,
      banner:[],
      banner2:[],
      news:[],
      steps:[],
      partner:[],
      footer_cate:[],
      question_index:[],
      product_serve:[],
      product_game:[],
      product_game_new:[],
      product_game_hot:[],
      mySwiper:{},
      mySwiper2:{},
      article_detail:{},
      screenWidth:document.documentElement.clientWidth || document.body.clientWidth,
    }
  },
  created(){
    this.get_article_detail()
    this.get_banner_list({ty:2})
    this.get_banner_list({ty:3,limit:5})
    this.get_product_serve()
    this.get_product_game_new()
    this.get_product_game_hot()
    this.get_question_index()
    this.get_new_index()
    this.get_step_list()
    this.get_partner_list()
  },
  mounted(){
    
  },
  methods:{
    get_article_detail(){
      this.$http.post("/api/index/get_article_detail",{id:88},{headers:{showLoading:false}}).then((res)=>{
          if (res.code == 200) {
            this.article_detail = res.data
            this.article_show = res.data.status == 1 ? true : false
          }
      })
    },
    hoverevent(realindex){
      if (this.screenWidth < 992) return
      this.mySwiper2.slideToLoop(realindex, 1000, false);
    },
    get_product_game_hot(){
      this.$http.post("/api/index/get_product_game_hot",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.product_game_hot = res.data
          }
      })
    },
    get_product_game_new(){
      this.$http.post("/api/index/get_product_game_new",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.product_game_new = res.data
          }
      })
    },
    get_product_game(id,index){
      this.$http.post("/api/index/get_product_game",{sid:id,limit:5},{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.$set(this.product_serve,index,{...this.product_serve[index],product_game:res.data})
          }
      })
    },
    get_product_serve(){
      this.$http.post("/api/index/get_product_serve",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.product_serve  = res.data
            for (let i = 0; i < res.data.length; i++) {
              this.get_product_game(res.data[i].id,i)
            }
          }
      })
    },
    get_question_index(){
      this.$http.post("/api/index/get_question_index",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.question_index = res.data
          }
      })
    },
    get_banner_list(data){
      this.$http.post("/api/index/get_banner_list",data,{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            if (data.ty == 2) {
              this.banner = res.data

              setTimeout(()=>{
                this.mySwiper =  new Swiper(".mySwiper", {
                  // loop: true,
                  direction: 'vertical',
                  slidesPerView:5,
                  observer:true,
                  observeParents:true,
                  watchSlidesProgress: true,
                  breakpoints: { 
                    320: {  //当屏幕宽度大于等于320
                      direction: 'horizontal',
                      slidesPerView:3,
                    },
                    768: {  //当屏幕宽度大于等于768 
                      direction: 'horizontal',
                      slidesPerView:3,
                    },
                    992: {  //当屏幕宽度大于等于1280
                      direction: 'vertical',
                      slidesPerView:5,
                    }
                  }
                });
                this.mySwiper2 = new Swiper(".mySwiper2", {
                  // loop: true,
                  observer:true,
                  observeParents:true,
                  autoplay: {
                    disableOnInteraction: false,
                  },
                  navigation: {
                    nextEl: '.swiper-banner-next',
                    prevEl: '.swiper-banner-prev',
                  },
                  thumbs: {
                    swiper: this.mySwiper,
                  },
                }); 
              },300)
              
            } else if (data.ty == 3) {
              this.banner2 = res.data
            }
          }
      })
    },
    get_new_index(){
      this.$http.post("/api/index/get_new_index",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.news = res.data
          }
      })
    },
    get_step_list(){
      this.$http.post("/api/index/get_step_list",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.steps = res.data
          }
      })
    },
    get_partner_list(){
      this.$http.post("/api/index/get_partner_list",{headers:{showLoading:true}}).then((res)=>{
          if (res.code == 200) {
            this.partner = res.data

            setTimeout(()=>{
              new Swiper ('#swiper-partner', {
                loop: true,
                slidesPerView :7,
                spaceBetween : 20,
                observer:true,
                observeParents:true,
                autoplay: {
                    disableOnInteraction: false,
                },
                breakpoints: { 
                  320: {  //当屏幕宽度大于等于320
                    slidesPerView: 2,
                    spaceBetween: 10
                  },
                  768: {  //当屏幕宽度大于等于768 
                    slidesPerView: 4,
                    spaceBetween: 15
                  },
                  1280:{
                    slidesPerView: 5,
                    spaceBetween: 15
                  },
                  1440: {  //当屏幕宽度大于等于1280
                    slidesPerView: 7,
                    spaceBetween: 20
                  }
                },
                navigation: {
                  nextEl: '.swiper-part-next',
                  prevEl: '.swiper-part-prev',
                },

              }) 
            },300)
           
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .body-bj {background-color: #eee;}
 .swiper-banner-next,.swiper-banner-prev {color: #fff;}
 .swiper-banner-next::after,.swiper-banner-prev::after {font-size: 40px;}
  .banner {background-color: #333333;padding: 30px 0;color: #fff;}
  .banner a {color: #fff;}
  .banner-left {width: 73.75%;}
  .banner-right {width: 25%;}
  .swiper-rbanner {height: 100%;background-color: #0c0020;}
  .br-ul>li,.swiper-rbanner .swiper-slide {padding: 10px 15px;}
  .swiper-rbanner .swiper-slide {border: 1px solid transparent;}
  .br-ul>li + li,.swiper-rbanner .swiper-slide + .swiper-slide {border-top: 1px solid #231f2b}
  .swiper-rbanner .swiper-slide-thumb-active {border: 1px solid #ff3100!important;}
  .br-left {width: 48.88%;overflow:hidden}
  .br-right {width: 51.12%;padding-left: 10px;}
  .br-tit {line-height: 1.4em;font-weight: 600;display: -webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;overflow: hidden;}
  .curr-top {background-repeat: no-repeat;background-position: center;background-size: cover;color: #fff;padding: 20px 25px;font-family: "ALGER";font-display: swap;}
  .curr-ul {padding: 10px 0 15px;}
  .curr-ul>li {border-radius: 10px;width: 49.16%;overflow: hidden;margin-top: 25px;cursor: pointer;}

  .curr-top-tit {font-size: 30px;white-space: nowrap;}
  .cub-ul>li {padding: 0 20px;transition: background-color .3s ease;}
  .cub-ul>li:hover {background-color: rgba(255,255,255,0.2);}
  .cub-ul {padding: 8px 0 15px;}
  .cub-left {max-width: calc(100% - 75px);}
  .cub-ul>li a {padding: 8px 0;border-bottom: 1px solid rgba(255,255,255,0.2);color: #fff;}
  .curr-bott {padding-bottom: 20px;}

  $color1-1:#8846ff;
  $color1-2:#5900b5;

  $color2-1:#1d244b;
  $color2-2:#333d7b;

  $color3-1:#0397ff;
  $color3-2:#0072ff;

  $color4-1:#ba8542;
  $color4-2:#88591f;

  .curr-bott::before,.curr-bott::after {content:"";position:absolute;width:100%;height:100%;left:0;top:0;background-repeat:no-repeat;}
  .curr-bott::before  {background-position:right top;background-size:18% auto}
  .curr-bott::after {background-position:left bottom;background-size:13% auto}

  $icon-color: #8846ff, #1d244b, #0397ff, #ba8542;
  $icon-color2: #5900b5, #333d7b, #0072ff, #88591f;
  @for $i from 1 through length($icon-color) {
    $item: nth($icon-color, $i);
    $item1: nth($icon-color2, $i);
    .curr-ul>li:nth-of-type(4n+#{$i}){
      
      background-color: $item;
      .view-btn {background-color: $item1;}
      // .curr-top {background-image:url(../../public/img/bj#{$i}.png);}
      .curr-bott::before {background-image: url(../../public/img/bb#{$i}.png);}
      .curr-bott::after {background-image: url(../../public/img/bb#{$i}.png);}
    }
  }


  @for $i from 1 through 3 {
    .late-ul>li:nth-of-type(3n+#{$i}) {
      .curr-top {background-image:url(../../public/img/bj#{$i+4}.png);}
    }
  }
  .cum-btop {width:75px;border-radius:10px;height: 75px;display: flex;justify-content: center;align-items: center;background-color: #efefef;}
  .cum-b-img {display: block;max-height: 100%;}
  .curr-binner {z-index: 2;}
  .cub-img {width: 30px;}
  .cub-btn {width: 70px;height: 22px;line-height: 20px;border: 1px solid #fff;border-radius: 10px;text-align: center;font-size: 12px;display: inline-block;}
  .view-btn {display: inline-block;width: 120px;height: 32px;line-height: 32px;border-radius: 4px;text-align: center;color: #fff;}
  .view-btn:hover {text-decoration: underline;}
  
  .late-ul>li {border-radius: 10px;overflow: hidden;background-color:#fff;padding-bottom:20px;margin-top:25px;}
  .late-ul>li:nth-of-type(3n+1),.late-ul>li:nth-of-type(3n+3) {width: 21.66%;}
  .late-ul>li:nth-of-type(3n+2) {width: 54.75%;margin-left:0.965%;margin-right:0.965%;}
  .cum-ul>li{padding: 0 20px;transition: background-color .3s ease;border-bottom: 1px solid #eeeeee;}
  .cum-ul {padding: 5px 0 0;}
  .hover-bj>li:hover {background-color: #eee;}
  .cum-ul>li a {padding: 8px 0;}
  .cum-ul2>li {font-size: 12px;}
  .cum-box-ul>li {width: 23.30%;margin-left: 2.25%;border: 1px solid #f0f0f0;margin-top: 13px;}
  .cum-box-ul>li a {padding: 15px 5px 14px;display: flex;flex-direction: column;align-items: center;}
  .cum-box-ul>li:nth-of-type(4n+1) {margin-left: 0;}
  .cum-box-ul>li:hover {background: url(../../public/img/cub-bj.png) no-repeat center;background-size: cover;}
  .cum-box-ul>li:hover {color: #fff;}
  .cum-box-ul>li:hover .cub-itit {color: #fff;}
  .cum-box-ul>li:hover .cub-inum {color: #fff;}
  .cum-bpd {padding: 2px 12px 0;}
  .cub-itit2 {margin: 13px 0 10px;font-weight: 600;font-size: 12px;}
  .cub-inum {font-size: 12px;color: #999;}
  .cum-more-btn {height: 33px;line-height: 33px;border: 1px solid #64bfff;text-align: center;font-size: 12px;color: #0397ff;display: inline-block;width: 226px;max-width: 96%;border-radius: 16px;white-space:nowrap}

  @mixin hover($bgcolor,$color) {
    background: $bgcolor;
    color: $color;
    transition: background-color .3s ease,color .3s ease;
  }
  .cum-more-btn:hover {
    @include hover(#64bfff,#fff)
  }

  .cum-ul2 > li:hover a {
    color: #0397ff;
  }

  .steps {padding:70px 0 40px;background:linear-gradient(to right,#4589c0,#34376e)}
  .step-tit {font-size:42px;text-transform:uppercase;font-weight:600}
  .steps-ul>li {width:16.66%;text-align:center;margin-top:40px;}
  .steps-tit {font-size:16px;margin:20px 0 10px;font-weight:600}
  .steps-desc {color:rgba(255,255,255,0.5);line-height:1.5em}

  .swiper-partner .swiper-slide{height:66px;display:flex;align-items:center;justify-content:center;border:1px solid #d2d2d2}
  .part-img {max-width:80%;max-height:80%;}
  .busi-pd {padding:0 2.08vw}
  .swiper-part-prev,.swiper-part-next {color:#9d9d9d}
  .swiper-part-prev::after,.swiper-part-next::after {font-size:40px;}
  .swiper-part-prev {left:0}
  .swiper-part-next {right:0}
  .busi {padding-bottom:60px;}
  .tit-more {font-size: 16px;font-weight: 600;}
  .news_tit_group {padding: 30px 0 30px;}
 .index-mask {position: fixed;top: 0;width: 100%;height: 100%;left: 0;z-index: 99;}
 .index-mask-inner {height: 60vh;background-color: #fff;width: 1200px;position: absolute;top: 55%;transform: translate(-50%,-50%);left: 50%;padding: 40px;border: 4px solid #090723;max-width: 94%;}
 .mask-tit {font-size: 28px;font-weight: 600;text-align: center;}
 .mask-desc {font-size: 16px;line-height: 1.875em;}
 .index-mask-inner .icon-close {position: absolute;right: 20px;top: 20px;font-size: 30px;cursor: pointer;}
 .index-mask-inner .icon-close:hover {color: #ff3100;}
  @media (min-width:992px) {
    .banner-right {height: 100%;position: absolute;top: 0;right: 0;}
  }
  @media (max-width:992px) {
    .index-mask-inner .icon-close {font-size: 20px;top: 15px;right: 15px;}
    .index-mask-inner {padding: 20px 15px;}
    .mask-desc {font-size: 14px;}
    .mask-tit {font-size: 18px;}
    .br-right {padding-left: 0;}
    .mySwiper-inner {display: flex;flex-direction: column;}
    .curr-top-tit {font-size: 20px;}
    .ft-ul>li {margin-right:15px;}
    .steps-ul > li {width:33.33%}
    .br-left {width:36%}
    .br-right {width:64%}
    .curr-ul {padding: 0;}
    .curr-ul > li {width:100%;margin-left:0!important;}
    .late-ul > li {width:100%!important;margin-left:0!important;margin-right:0!important;}

    .swiper-banner-prev::after,.swiper-banner-next::after,.swiper-part-next::after,.swiper-part-prev::after {font-size:20px;}
    .step-tit {font-size:20px;}
    .steps {padding:40px 0;}
    .step-img {width:55px;}
    .steps-ul > li {margin-top:25px;}
    .busi-pd {padding:0}
    .news_tit_group {padding:25px 0 20px;}
    .news_tit {font-size:20px;}
    .busi{padding-bottom:40px;}
    .tit-more {font-size:14px;}
    .cub-ul > li,.cum-ul > li {padding:0 15px;}
    .curr-top {padding:15px 20px;}
   
    .banner {padding:20px 0;}
  }
  @media (max-width:640px) {
    .steps-ul > li {width:50%}
    .cum-box-ul > li {width:48.5%;margin-left:3%}
    .cum-box-ul > li:nth-of-type(4n+1) {margin-left:3%}
    .cum-box-ul > li:nth-of-type(2n+1) {margin-left:0}
  }
</style>
