import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:null,
    identify:null,
    showiframe:false
  },
  getters: {
    getToken(state){
      console.log(state.token,"state")
      return state.token || localStorage.getItem("token") || "";
    },
    getIdentify(state){
      return state.identify || localStorage.getItem("identify") || ""
    }
  },
  mutations: {
    setToken(state,token) {
      state.token = token;
      localStorage.setItem('token', token);
      console.log('store、localstorage保存token成功！');
    },
    setIdentify(state,identify){
      state.identify = identify;
      localStorage.setItem('identify', identify);
      console.log('store保存identify成功！');
    },
    delToken(state) {
      state.token = "";
      state.identify = "";
      localStorage.removeItem("token");
      localStorage.removeItem('identify');
      console.log('删除token和identify成功')
    }
  },
  actions: {
  },
  modules: {
  }
})
