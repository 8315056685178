<template>
  <div id="app">
    <HeadTop v-if="$route.meta.topfooter"></HeadTop>
    <router-view/>
    <FooterBott v-if="$route.meta.topfooter"></FooterBott>
  </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import FooterBott from '@/components/FooterBott.vue'
export default {
  components: {
    HeadTop,
    FooterBott
  }
}
</script>
<style lang="scss">
 @font-face {
    font-family: "GOTHIC";
    src: url('@/assets/font/GOTHIC.TTF?t=1693621075668') format('truetype');
  }
  @font-face {
    font-family: "ALGER";
    src: url('@/assets/font/ALGER.TTF?t=1693621075668') format('truetype');
  }
  .normal-style {font-style: normal;}
  html{ color: #333;font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;}
  body {font-size: 14px;}
  body,div,h1,h2,h3,h4,h5,h6,hr,p,blockquote,dl,dt,dd,ul,ol,li,pre,form,fieldset,legend,button,input,textarea,th,td,a,:before,:after{margin:0;padding:0;box-sizing: border-box;}
  ul,ol,li{list-style:none;}
  a{text-decoration: none;color: #333}
  .auto{margin-left:auto;margin-right:auto;}
  .l{float:left;}.r{float:right;}.ti2{text-indent: 2em;}
  .db{display:block;}.dn{display:none;}.dib{display: inline-block;}
  .ovh{overflow:hidden;}.rel{position:relative;}.abs{position:absolute;}
  .rel{position:relative;}.abs{position:absolute;}
  .img-responsive{max-width: 100%; height: auto; display: block;}
  .ellipsis{-o-text-overflow: ellipsis;text-overflow: ellipsis; overflow: hidden; display: block; white-space: nowrap;}
  .ellipsis1{-o-text-overflow: ellipsis;text-overflow: ellipsis; overflow: hidden; display: inline-block; white-space: nowrap;}
  img {border:none;display: inline-block;height: auto;max-width: 100%;}
  .fix-web:after{display:block;content:"clear";height:0;clear:both;overflow:hidden;visibility:hidden;}
  .m0{margin: 0px;}
  .m1{margin: 1px;}.ml1{margin-left: 1px;}.mt1{margin-top: 1px;}.mr1{margin-right: 1px;}.mb1{margin-bottom: 1px;}
  .m2{margin: 2px;}.ml2{margin-left: 2px;}.mt2{margin-top: 2px;}.mr2{margin-right: 2px;}.mb2{margin-bottom: 2px;}
  .m5{margin: 5px;}.ml5{margin-left: 5px;}.mt5{margin-top: 5px;}.mr5{margin-right: 5px;}.mb5{margin-bottom: 5px;}
  .m10{margin: 10px;}.ml10{margin-left: 10px;}.mt10{margin-top: 10px;}.mr10{margin-right: 10px;}.mb10{margin-bottom: 10px; font-weight: 600;}
  .m15{margin: 15px;}.ml15{margin-left: 15px;}.mt15{margin-top: 15px;}.mr15{margin-right: 15px;}.mb15{margin-bottom: 15px;}
  .m20{margin: 20px;}.ml20{margin-left: 20px;}.mt20{margin-top: 20px;}.mr20{margin-right: 20px;}.mb20{margin-bottom: 20px;}
  .mt30 {margin-top: 30px;}
  /*padding*/
  .p0{padding: 0px;}
  .p1{padding: 1px;}.pl1{padding-left: 1px;}.pt1{padding-top: 1px;}.pr1{padding-right: 1px;}.pb1{padding-bottom: 1px;}
  .p2{padding: 2px;}.pl2{padding-left: 2px;}.pt2{padding-top: 2px;}.pr2{padding-right: 2px;}.pb2{padding-bottom: 2px;}
  .p5{padding: 5px;}.pl5{padding-left: 5px;}.pt5{padding-top: 5px;}.pr5{padding-right: 5px;}.pb5{padding-bottom: 5px;}
  .p10{padding: 10px;}.pl10{padding-left: 10px;}.pt10{padding-top: 10px;}.pr10{padding-right: 10px;}.pb10{padding-bottom: 10px;}
  .p15{padding: 15px;}.pl15{padding-left: 15px;}.pt15{padding-top: 15px;}.pr15{padding-right: 15px;}.pb15{padding-bottom: 15px;}
  .p20{padding: 20px;}.pl20{padding-left: 20px;}.pt20{padding-top: 20px;}.pr20{padding-right: 20px;}.pb20{padding-bottom: 20px;}
  .normal-select {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background-color:transparent
  }
  .responsive-x {overflow-x: auto;}
  .responsive-y {overflow-y: auto;}
 .normal-table {width: 100%;border-collapse: collapse;}
  button {
    border: none;
    outline: none;
    background-color: transparent;
  }

  #canvas {position: absolute;}
  textarea.el-textarea__inner {resize: none;font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;}

 .hide-pc {display: none;}
  .flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .flex-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .align-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .flex-reverse {
    flex-direction: row-reverse;
  }
  .align-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .align-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .space-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .flex-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .just-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .flex1 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .just-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .msg-iframe-box {width: 600px;height: 500px;position: fixed;top: 50%;transform: translate(-50%,-50%);left: 50%;z-index: 999;}
  .msg-iframe {width: 100%;height: 100%;}
  .msg-iframe-box .icon-closefill {position: absolute;right: 0;top: 0;font-size: 32px;color: #dd1e16;cursor: pointer;}
  .abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    z-index:9;
  }
  [class*=btn] {cursor: pointer;}
  .fs12 {font-size: 12px;}
  .tc{text-align: center;}
  .br10 {border-radius: 10px;}
  .block {display: block;width: 100%;}
  .bgfff {background-color: #fff;}
  .center-box {max-width: 1200px;margin-left: auto;margin-right: auto;}
  .center-box2 {max-width: 1500px;margin-left: auto;margin-right: auto;}
  .webkit {display: -webkit-box;-webkit-box-orient:vertical;overflow: hidden;}
  .line1 {-webkit-line-clamp: 1;}
  .col-fff {color:#fff}
  .hover-tit>li [class*=tit] {transition:color .3s ease}
  .hover-tit>li:hover [class*=tit] {color:#64bfff}

  .hover-tit1>li [class*=tit] {transition:color .3s ease}
  .hover-tit1>li:hover [class*=tit] {color:#dd1e16}
  .col-red {color: #dd1e16;}
  .under-line {text-decoration: underline;}
  .hover-under:hover {text-decoration: underline;}
  .bold {font-weight: 600;}

  .el-pagination {text-align: center;padding: 60px 0;}
  .el-pager li {outline: 1px solid #444950;}
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{background-color: transparent;}
  .el-pagination.is-background .el-pager li:not(.disabled).active {background-color: #dd1e16;outline-color: #dd1e16;}
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #fff;
  }


  .user-table {width: 100%;border-collapse: collapse;color: #a2a0ab;font-size: 14px;min-width: 680px;}
  .user-table .type-img {width: 60px;border-radius: 10px;}
  .evalute-table .type-img {width: 60px;border-radius: 10px;}
  .trade-status {display: inline-block;min-width: 120px;height: 28px;line-height: 28px;border: 1px solid #5785a4;font-size: 14px;text-align: center;color: #5785a4;border-radius: 4px;}
  .user-table th {background-color: #151c24;font-weight: 400;font-size: 18px;}
  .user-table th,.user-table td {padding: 15px;}
  .user-table td {background-color: #21282f;}
  .user-table tr {border-bottom: 1px solid #151c24;}
  .user-table th:nth-of-type(1),.user-table th:nth-of-type(2),.user-table th:nth-of-type(4) {text-align: left;}
  .user-table th:nth-of-type(1),.user-table td:nth-of-type(1) {padding-left: 20px;}


  .busi-proinfo-table .icon-close-circle {font-size: 20px;}
  .busi-proinfo-table {color: #a3a3a3;text-align: center;min-width: 600px;}
  .busi-proinfo-table th {padding: 10px 6px;background-color: #12161a;font-size: 16px;}
  .busi-proinfo-table td {padding: 20px 6px;background-color: #21282f;border-bottom: 1px solid #151c24;}
  // .busi-proinfo-table th:first-child,.busi-proinfo-table td:first-child {text-align: center;padding-right: 0;white-space: nowrap;}
  .trad-bott {padding-top: 20px;}
  .busi-proinfo-tit {max-width: 190px;}
  .trad-bul-top,.trad-bul-bott {background-color: #21282f;padding-top: 10px;padding-bottom: 10px;}
  .busi-pro-img {border-radius: 4px;width: 74px;}
  .busi-pro-info-expired {color: #767778;}

  .trad-select {width: 175px;height: 35px;line-height: 35px;border: 1px solid #383e44;padding: 0 15px;color: #fff;background: url(../public/img/arrow-down-fill-white.png) no-repeat 96% center;}
  .trad-select option {color:#333}
  .trad-btr-ul>li {width: 35px;height: 35px;border: 1px solid #3e444a;border-radius: 2px;text-align: center;line-height: 35px;margin-left: 10px;cursor: pointer;}
  .trad-btr-ul>li.on {background-color: #21282f;}

  .trad-bott-box {background-color: #151c24;padding: 50px 0 0;color: #9e9e9e;}
  .trad-info a {color: #9e9e9e;}
  .trad-bul {padding-top: 20px;}
  .trad-bul>li {margin-top: 10px;}
  .trad-bul-pd {padding-left: 20px;padding-right: 20px;}
  .trad-info {padding-left: 10px;}
  .trad-icon-a + .trad-icon-a {margin-left: 10px;}
  .trad-avatar {width: 32px;border-radius: 4px;}
  .trad-pro-img {width: 75px;border-radius: 4px;display: block;object-fit:cover;aspect-ratio: 1 / 1;}
  .trd-pro-tit {font-size: 16px;color: #fff;}
  .trad-bb-left {width: 44.4%;}
  .unidesc {font-size: 16px;}
  .buy-btn {display: inline-block;width: 125px;height: 44px;line-height: 44px;border-radius: 22px;background-color: #dd1e16;color: #fff;font-size: 18px;text-align: center;margin-right: 10px;font-weight: 600;}

  .trad-bl-item {min-width: 210px;padding-right: 20px;}
  .trad-bul:not(.trad-bul1) .trad-bul-bott {margin-top: 1px;}
  .trad-bul:not(.trad-bul1) .trd-pro-stock,.trad-bul:not(.trad-bul1) .unidesc {margin-top: 10px;}
  .trad-bul:not(.trad-bul1) .trd-pro-info {margin-left: 15px;}

  .trad-bul1 {display: flex;flex-wrap: wrap;}
  .trad-bul1>li {width: 22.1875%;margin-left: 3.75%;margin-top: 35px;border-radius: 10px;overflow: hidden;}
  .trad-bul1>li:nth-of-type(4n+1) {margin-left: 0;}
  .trad-bul1>li {display: flex;flex-direction:column-reverse;}
  .trad-bul1>li .trad-bl-item {padding-right: 0;min-width: auto;}
  .trad-bul1>li .trad-bl-item:nth-of-type(2) {display: none;}
  .trad-bul1>li .trad-bul-bott {display: flex;flex-direction: column;flex:1}
  .trad-bul1>li .trad-bb-left {display: flex;flex-direction: column;}
  .trad-bul1>li .trad-bb-right {display: flex;flex-direction: column;width: 100%;flex: none;align-items: flex-start;}
  .trad-bul1>li .trad-bb-left {width: 100%;}
  
  .hide-pc {display: none;}

  .trad-bul1>li .trad-bbr-item,.trad-bul1>li .trd-pro-stock {margin-top: 15px;}
  .trad-bul1>li .trd-pro-info {margin-top: 25px;}

  .trad-bul1>li .trad-bbr-item {display: flex;align-items: center;width: 100%;}
  .trad-bul1>li .buy-btn,.buy-btn2 {margin-right: 0;width: 100%;border-radius: 4px;height: 35px;line-height: 35px;font-size: 14px;color: #fff;}

  .td-position {padding: 15px 0;font-size: 16px;color: #151c24;background-color: #e0e0e0;}
  .td-position a {color: #151c24;}

  .min-box-vice-tit {font-size: 16px;color: #929295;margin-bottom: 20px;}
  .ut-sn {color: #349ff8;}
  .trad-uname{max-width: 110px;-o-text-overflow: ellipsis;text-overflow: ellipsis; overflow: hidden; display: inline-block; white-space: nowrap;}
  .td-eval-top,.td-eval-top a {color: #e0e0e0;}
  .td-eval-ul>li {background-color: #2c323a;padding: 15px 20px 25px;color: #999;}
  .td-eval-ul>li + li {margin-top: 15px;}
  .td-eval-comm {background-color: #242a32;padding: 10px 10px 15px;line-height: 1.5em;}
  .td-eval-comm2 {background-color: #1d242b;}
  .col-333 {color: #333;}
  .col-666 {color: #666;}
  .col-999 {color: #999;}

  .step-box .el-input-number__decrease, .el-input-number__increase {background-color: #7d8185;color: #444444;font-weight: 600;}
  .step-box  .el-input-number__increase {border-left-color: #7d8185;}
  .step-box  .el-input-number__decrease {border-right-color: #7d8185;}
  .step-box .el-input__inner {background-color: #40464c;color: #a9a8b2;border: none;}
  .step-box .el-input-number__decrease:hover, .step-box .el-input-number__increase:hover {color: #fff;}

  .red-radio .el-radio__input.is-checked .el-radio__inner {background:#dd1e16;border-color:#dd1e16}
  .red-radio .el-radio__input.is-checked + .el-radio__label {color:#898997}

  .td-oper-change {padding:15px 0 0 0;}
 
  .tr {text-align: right;}
  .banner-list-desc {font-size: 1.875vw;color: #ffffff;line-height: 1.45em;text-shadow: 0 0 3px rgba(142,142,142,0.5);}
  .banner-list-word {position: absolute;top: 50%;transform: translate(0,-50%);width: 100%;left: 0;}
  .banner-list-tit {font-size: 3.125vw;text-transform: uppercase;font-weight: 600;}
  .banner-list-logo {max-width: 14vw;}

  .news_ {padding-bottom:60px;}
  .news_ul {margin-top: -30px;}
  .news_ul>li:hover {box-shadow:0 0 5px rgba(142,142,142,0.5);transform:scale(1.1)}
  .news_tit {font-size: 30px;font-weight: 600;text-transform:uppercase}
  .news_ul>li {width:22.83%;margin-left:2.88%;background-color:#fff;font-size:16px;transition:box-shadow .3s ease,transform .3s ease;border-radius:10px;overflow:hidden;margin-top: 30px;}
  .news_ul>li:nth-of-type(4n+1) {margin-left:0}
  .news-tit {font-weight:600;display:-webkit-box;-webkit-line-clamp: 2;overflow:hidden;-webkit-box-orient:vertical;line-height:1.5em}
  .news_bott {padding:15px 10px;}
  .news_time {color:#999;margin-top:10px}

.login-top {position: fixed;top: 0;z-index: 99;width: 100%;}
.login-outer-box {max-width:94%}
.loginpage {background:url(../public/img/login-bj.png) no-repeat center;background-size:cover;width:100vw;height:100vh;overflow-y:auto;}
.select-group1 {width:132px;height:42px;background-color:#c81a1f;padding-left:20px;border-radius:0 0 10px 10px;}
.country-select {background: url(../public/img/arrow-down-fill-white.png) no-repeat 96% center;padding-right: 16px;text-align: center;color: #333;font-size: 12px;font-size:15px;color:#fff;}

.agreement {font-size: 12px;text-align: center;padding: 20px 0;color: #fff;}
.login-logo-box{margin-bottom:30px;}
.login-logo {max-width:9.89vw}
.login-inner {width:575px;background-color:#fff;border-radius:10px;max-width:100%}
.login-form {padding:30px 38px 20px}
.login-tit {font-size:36px;color:#e7262c;font-weight:600;text-align:center;margin-bottom:22px;}
.posi-code {
  position:absolute;
  top:1px;
  right:1px;
  height:38px;
  // width:140px;
  border-left:1px solid #DCDFE6;
  border-radius:0 4px 4px 0;
}
.login-btn {height:45px;line-height:45px;display:inline-block;width:100%;background-color:#dd1e16;color:#fff;font-size:16px;text-align:center;border-radius:30px}


.other-link2 {
  width: 40px;
  padding: 0;
  border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s,border-color .218s;
    transition: background-color .218s,border-color .218s;
    -webkit-user-select: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadce0;
    color: #3c4043;
    cursor: pointer;
    font-family: "Google Sans",arial,sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-link2:hover {
  -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #d2e3fc;
    outline: none;
}

.link-img {max-height: 60%;pointer-events: none;}
.form-or {position:relative;font-size:16px;color:#7b7b7b;margin:20px 0 20px;}
.form-or:before,.form-or:after {content:"";display:block;width:calc(50% - 20px);position:absolute;top:50%;height:1px;background-color:#eeeeee;transform:translate(0,-50%)}
.form-or:before {left:0;}
.form-or:after {right:0}
.forgot {margin-bottom:22px;}
.other-link + .other-link {margin-left:30px}
.login-bott {background-color:#eeeeee}
.login-bul>li {flex:1;padding:10px 0 15px;text-align:center}
.licon-btit {font-size:14px;color:#000;margin:10px 0}
.licon-bdesc {color:#9d9d9d;line-height:1.5em;max-width:200px;margin-left:auto;margin-right:auto;font-size: 12px;}
.tl {text-align: left;}

.code-box .el-form-item__content {display:flex;align-items:center}
.vericode[disabled] {
  // background-color: #ccc;
  opacity: 0.5;
}
.el-avatar>img {width: 100%;}
.get_code_btn[disabled] {
  opacity: 0.5;
}
.vericode {height:40px;background-color:#0075a9;color:#ffff;width:140px;margin-left:10px;border-radius:4px;cursor:pointer}
.code-box .el-form-item__content .el-input {flex:1}
.login-inner .el-input__prefix {left:8px}

.main-box {padding-top: 25px;}
.main-box-bj {background-color: #151c24;}
.min-box-tit {font-size: 36px;color: #a2a0ab;margin-bottom: 35px;}

.verify-btn {display: inline-block;width: 194px;height: 38px;line-height: 38px;background-color: #2780e3;border-radius: 25px;color: #fff;text-align: center;font-size: 16px;}
.verify-btn-box {margin: 30px 0;}
.col-sky {color: #6eb9df;}
.col-blue {color: #1677ff;}
.submit-btn {width: 120px;height: 50px;line-height: 50px;background-color: #dd1e16;color: #fff;text-align: center;font-size: 16px;border-radius: 24px;}
.send-vari-box {padding: 20px 0 0 20px;}
.send-vari-btn {display: inline-block;width: 210px;height: 40px;line-height: 40px;background-color: #b72a2b;color: #fff;text-align: center;border-radius: 4px;font-size: 16px;}

.normal-place ::-webkit-input-placeholder{
  color: #999;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.normal-place ::-moz-placeholder{
  color: #999;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.normal-place :-ms-input-placeholder{
  color: #999;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.ut-kao,.ut-price {white-space: nowrap;}
.flex-row .main-right {width:calc(100% - 290px);}
.main-out-box {background-color: #21282f;}
.main-rtop-box {padding: 30px 0 25px;border-bottom: 1px solid #21282f;background-color: #21282f;}
.main-rtop{color: #fff;padding:0 20px 0 40px;}
.rt-cimg {position: absolute;top: 4px;left: 0;max-width: 28px;}
.main-rit {font-size: 26px;}
.rt-pd {padding-left: 20px;padding-right: 20px;}

.busi-region-width-4 {width: 25%;padding-left: 10px;margin-bottom: 15px;}
.busi-region-box {padding: 20px;background-color: #21282f;}

.store-info-tit {font-size: 32px;color: #fff;}
.dis-img-box .el-image {margin-right: 10px;}
.main-rchoose .el-form {display: flex;flex-wrap: wrap;justify-content: space-between;align-items: flex-end;}
.main-rchoose .el-form-item__label {padding-right: 0;}
.mrc-left {width: 80%;}
.mrc-left .el-form-item {width: 48%;display: inline-flex;}
.mrc-left .el-form-item__content {flex: 1;}
.mrc-left .el-form-item__content .el-select {display: block;}
.mrc-left .el-input__inner {background: transparent;border-color: #535353;color: #fff;}
.stripe-tr {background-color: #090723;border-top: 10px solid #151c24;}

.main-table {color: #999999;margin-top: 12px;text-align: center;min-width: 600px;}
.main-table th {background-color: #21282f;}
.main-table th {padding: 20px;font-size: 18px;font-weight: 400;}
.main-table td {padding: 10px 15px;}
.stripe-tr2 td {background-color: #21282f;}
.stripe-tr3 td {padding: 20px;}
.traiper-tr-inner {padding-right: 40px;position: relative;}
.traiper-tr-inner .icon-delete {position: absolute;right: 0;top: 0;font-size: 24px;}
.main-table .type-img {width: 46px;}
.ii-mid {text-transform: uppercase;}
.ut-status {width: 108px;height: 30px;line-height: 28px;text-align: center;border-radius: 4px;color: #349ff8;font-size: 12px;border: 1px solid #349ff8;display: inline-block;white-space: nowrap;cursor: pointer;}
.ut-status.pay {border-color: #1fc0b7;color: #1fc0b7;}
.ut-status.eva {border-color: #3b7eec;color: #3b7eec;}
.ut-status.on {background-color: #dd1e16;color: #fff;border-color: #dd1e16;}
.mrc-btn {width: 135px;height: 36px;line-height: 36px;display: inline-block;background-color: #dd1e16;color: #fefefe;margin-bottom: 22px;}
.el-image * {background-color: transparent;}
.regist_save_btn_box {margin-top: 40px;}
.regist_save_btn {display: inline-block;width: 120px;height: 50px;line-height: 50px;border-radius: 25px;background-color: #dd1e16;color: #fff;text-align: center;font-size: 16px;}

.disp-form .el-textarea__inner {height: 238px;background-color: #21282f;border-color: #21282f;} 

.disp-form .avatar-uploader .el-upload {
    background-color: #21282f;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // font-size: 60px;
    color: #898997;
  }

  .disp-form .el-upload--picture-card {
    background-color: #21282f;
  }
  .disp-form .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  // .disp-form .avatar-uploader-icon {
  //   width: 170px;
  //   height: 170px;
  //   line-height: 170px;
  //   text-align: center;
  // }
  // .disp-form .avatar {
  //   width: 170px;
  //   height: 170px;
  //   display: block;
  // }

.dispute-inner {padding: 35px 30px 75px;background-color: #21282f;}
.dis-desc {background-color: #1d242b;padding: 12px;}
.dispute-step-ul {margin-top: 50px;color: #a2a0ab;}
.dispute-step-ul>li {padding-left: 30px;position: relative;padding-bottom: 30px;}
.dispute-step-ul>li::before {content: "";display: block;width: 2px;height: 100%;background-color: #71a7ff;position: absolute;left: 7px;top: 18px;}
.dispute-step-ul>li.active {padding-bottom: 0;}
.dispute-step-ul>li.active .blue-circle {width: 24px;height: 24px;left: -5px;text-align: center;line-height: 24px;}
.dispute-step-ul>li.active::before {display: none;}
.dispute-step-ul>li .blue-circle {content: "";display: block;width: 15px;height: 15px;border-radius: 50%;background-color: #0162fe;color: #fff;position: absolute;left: 0;top: 3px;}
.dis-tit {font-size: 16px;}
.dis-info {line-height: 1.8em;}
.dis-top {font-size: 16px;color: #ffffff;}
.dis-avatar {border-radius: 50%;width: 30px;}
.dis-img-box {padding-top: 25px;}

.register_box {background-color: #21282f;padding: 50px 60px;}
.register_tit {font-size: 35px;color: #dd1e16;}
.register_info {font-size: 16px;color: #525257;margin-top: 15px;}
.register_item_tit {font-size: 32px;color: #898997;padding-top: 25px;padding-bottom: 20px;}
.register_item_pd {padding-left: 30px;padding-right: 30px;}
.register_item .el-form-item__label {float: none;}
.register_iinner {padding-top: 25px;padding-bottom: 40px;background-color: #21282f;}
// .register_item .el-form-item {margin-bottom: 0;}
.register_item .el-input__inner {background-color: #151c24;border-color: #767676;color: #fff;}
.register_border {padding: 10px;border: 1px solid #2c333a;border-radius: 4px;color: rgb(255, 255, 255,0.2);}
.register_warning {color: #ff3b1a;line-height: 1.6em}
.register_2 .el-form-item {width: 48%;}
.el_form_row .el-input__inner {padding-left: 65px;}
.register_item .el-select {display: block;}
.h_icon {position: absolute;left: 1px;height: calc(100% - 2px);width: 50px;background-color: #31383f;top: 50%;transform: translate(0,-50%);border-radius: 4px 0 0 4px;display: flex;justify-content: center;align-items: center;}
.register_select {margin-top: 25px;}
.n_icon {position: absolute;top: 50%;transform: translate(0,-50%);right: 1px;width: 40px;text-align:center;font-weight: 600;cursor: pointer;}
.n_icon .iconfont {font-size: 18px;color: #fff;}
.register_select_item + .register_select_item {margin-top: 15px;}
.h_icon-img {width: 50%;}
.shop-inner {padding-bottom: 60px;}
.register_iinner + .register_iinner {margin-top: 1px;}
.register_check_text {color: #65686c;}
.register_check_btn_box {margin-top: 50px;}
.register_check_btn {display: inline-block;width: 180px;height: 47px;line-height: 47px;border-radius: 24px;background-color: #f03726;text-align: center;color: #fff;font-size: 16px;}
.register_varify_email_box {background-color: #151c24;padding: 15px 20px;color: #898997;font-size: 16px;}
.register_varify_input_box {margin-top: 25px;}
.register_get_code {margin-top: 40px;}
.back_btn {display: inline-block;width: 140px;height: 47px;line-height: 47px;border-radius: 24px;background-color: #151c24;text-align: center;color: #fff;font-size: 16px;}
.get_code_btn {display: inline-block;width: 150px;height: 40px;line-height: 40px;color: #898997;border: 1px solid #898997;}
.get_code_warning {font-size: 12px;margin-left: 20px;}
.get_code_warning .iconfont {font-size: 14px;}
.retister_idcar_btn {display: inline-block;width:316px;height: 46px;line-height: 46px;border: 1px solid #444444;text-align: center;font-size: 16px;color: #898997;}
.register_card_item {width: 48%;}
.register_card_img_box {margin-bottom: 30px;}
.register_card_img {width: 317px;height: 186px;border-radius: 10px;}
.res_result_btn {display: inline-block;width: 112px;height: 32px;line-height: 32px;border: 1px solid #767676;border-radius: 16px;text-align: center;font-size: 16px;color: #888e95;}
.res_result_btn.on {background-color: #8d8d8e;color: #151c24;}
.res_result_btn + .res_result_btn {margin-left: 48px;}
.re_result_box {background-color: #21282f;padding: 130px 0 90px;color: #888e95;}
.re_result_status {font-size: 36px;margin: 25px 0;}
.re_result_desc {font-size: 16px;margin-bottom: 35px;}
.wait .fail, .wait .success,.fail .wait,.fail .success,.success .wait,.success .fail {display: none;}
.wait .wait,.success .success,.fail .fail {display: inline-block;}

@keyframes van-cursor-flicker {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.register_varify_input_box {

    .code-item {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #767676;
        position: relative;
        color: #fff;
    }
    .code-item + .code-item {margin-left: 15px;}
    .input-code {position: absolute;left: -50px;}
    .el-input__inner {
      width: 365px;
      height: 40px;
      background-color: transparent;
      border: none;
      color: transparent;
      padding: 0;
      user-select: none;
    }
    .code-item-active {
        border-color:  #F56C6C;
    }
    .van-password-input__cursor {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 40%;
        background-color: #fff;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation: 1s van-cursor-flicker infinite;
        animation: 1s van-cursor-flicker infinite;
    }
    .cursor_left_posi {
      left: calc(50% + 5px);
    }
}
[v-cloak] {
    display: none;
}
.main-left-ul>li.on .icon-ar-down {transform: rotate(-180deg);}
.icon-ar-down {display: inline-block;width: 12px;height: 12px;background:url(../public/img/ar-down.png) no-repeat center;background-size: 100% auto;transition: transform .3s ease;}
.main-drop,.main-drop a {color: #fff;}
.main-drop {font-size: 18px;font-weight: 600;padding: 20px;background-color: #090723;cursor: pointer;}
.flex-row .main-left {width: 260px;}
.md-ul>li {color: #a3a3a3;cursor: pointer;background-color: #151c24;}
.md-ul>li.on,.md-ul>li:hover {background-color: #21282f;}
.md-ul>li a {color: #a3a3a3;padding: 20px 0 20px 30px;display: block;font-size: 16px;}
.uo-cimg {width: 26px;}
.main-drop-cont {display: none;}
button.request_btn[disabled],.send-vari-btn[disabled] {filter: grayscale(.5);opacity: 0.5;}
.main-mt-reverse {margin-top: -4.68vw;}
.marin_r_form  .el-input__inner {background-color: #21282f;border-color: #767676;color:#fff}
.marin_r_form .secret_code_box .el-input__inner {background-color: transparent;}
.marin_r_form .el-form-item__label {float: none;}
.marin_r_form .el-select {display: block;}
.re_inner_warp_2 .el-form-item {width: 48%;}
.re_inner_pd {padding-left: 40px;padding-right: 40px;}
.re_inner_warp_radio .el-radio-group{display: block;}
.re_r_form_item {padding-bottom: 40px;background-color: #21282f;}
.re_r_form_item + .re_r_form_item {margin-top: 1px;padding-top: 30px;}
.marin_r_vice_title {font-size: 18px;color: #ffffff;margin-bottom: 15px;}
.line-normal {line-height: inherit;display: inline-flex;align-items: center;}

.evalute-table {color: #999999;min-width: 600px;}
.evalute-table tr {border: 1px solid #373e46;}
.evalute-table td,.evalute-table th {padding: 10px 20px;background-color: #151c24;}
.view-detail-btn {display: inline-block;width: 125px;height: 30px;line-height: 30px;border-radius: 4px;border: 1px solid #4d4d4d;text-align: center;color: #545454;}

.busi-region-form .el-input__inner {background-color: transparent;color: #fff;}
.busi-region-form .el-form-item {margin-bottom: 0;}
.main-tr-tit {padding: 25px 0 15px;}
.tr-bt {border-top: 1px solid #373e46;}

.step-bj {background-color: #21282f;padding: 40px 35px 110px;}
.agree-show {font-size: 16px;color: #7d8186;line-height: 1.66em;}
.agree-show p {margin: 30px 0;}
.agree-inner {padding-bottom: 124px;}
.has-agree-btn {display: inline-block;background-color: #ff7518;color: #fff;height: 48px;line-height: 48px;padding: 0 20px;}

.is_mo {display:inline-block;width:auto;height:24px;line-height:24px;border-radius:12px;background-color:#21282f;color:#545d68;padding:0 15px}
.is_mo.on {background-color:#aa000e;color:#fff}
  .bb-none {border-bottom: none;}
  .mt0 {margin-top: 0;}
  .trad-icon {width: 16px;}
  .main-pb {padding-bottom: 110px;}
  .trad-bul1>li .trad-pro-img {width: 153px;margin-left: auto;margin-right: auto;}
  .trad-bul1>li .trad-bul-bott {margin-bottom: 1px;}
  .trad-bul1>li .trad-bult-right {white-space: nowrap;}
  .trad-bul1>li .trad-bb-left {align-items: flex-start;}
  .trad-bul1>li .hide {display: none;}
  .width-step-ul>li {background: url(../public/img/step-bj.png) no-repeat center;background-size: 100% 100%;padding: 12px 0;width: 26.125%;color: #fff;text-align: center;}
  .width-step-ul>li.on {background-image: url(../public/img/step-ul-bj.png);}
  .width-step-ul>li + li {margin-left: -1.5%;}
  
  .width-draw-money {font-size: 24px;color: #dd1e16;}
  .width-draw-text {color: #444;margin-top: 10px;font-size: 14px;}

  .bindcount-form .el-input__inner {background-color: transparent;border: none;}
  .width-step-ul {margin-top: -20px;}
  @media (min-width:992px) {
      .bindcount-form .el-input__inner {
          font-size: 24px;
          width: 240px;
          padding-left: 40px;
      }
      .bindcount-form .icon-yinhangqia {font-size: 28px;}
  }

  .create-shop-warning {background-color: #21282f;padding-top: 20px;padding-bottom: 20px;color: #a3a3a3;line-height: 1.4em;}
.create-shop-warning-text {padding-left: 30px;}
.create-shop-warning-text .icon-zhuyix {position: absolute;top: 4px;left: 0;}
  .count-table {color: rgba(225,225,225,0.2);min-width: 600px;}
  .count-table td,.count-table th {padding: 15px;}
  .count-table td:first-child,.count-table th:first-child {padding-right: 0;}
  .count-table tr {border-bottom: 1px solid #21282f;cursor: pointer;}
  .count-table tr.on {color: #fff;border: 1px solid #21282f;}
  .el-table-box {background-color: #151c24;padding: 20px;border-radius: 10px;}
  .bank-img-box {width: 28px;text-align: center;margin-right: 5px;}
  .count-table .el-radio {display: none;}
  .count-table tr.on .el-radio {display: block;}
  .count-table .el-radio__label {display: none;}

  .busi-region-form .el-checkbox-group {font-size: inherit;}
  .busi-region-table-box {padding: 15px;color: #9292a2;}
  .web-region .el-select {display: block;}

  .register_iinner_tit {
    font-size: 24px;
    color:#999;
    margin-bottom: 22px;
  }
.store-info-desc {padding: 10px 15px;background-color: #21282f;color: #929295;line-height: 1.5em;}
.store-info-vice-tit {font-size: 18px;color: #929295;padding-top: 10px;padding-bottom: 10px;}
.store-info-pd {padding-left: 30px;padding-right: 30px;background-color: #090723;}
.store-info-item {padding-top: 20px;}
.store-info-bott {background-color: #21282f;padding-top: 20px;padding-bottom: 20px;color: #929295;}
.store-info-theme {color: #2780e3;}
.store-info-vice-item + .store-info-vice-item {margin-top: 40px;}
.el-load-btn {display: inline-block;width: 367px;height: 53px;line-height: 53px;background-color: #dd1e16;color: #fff;text-align: center;font-size: 18px;border-radius: 26px;max-width: 100%;}

#app {min-height: 100vh;display: flex;flex-direction: column;}
.main-box-bj {flex: 1;}
.image-slot {width: 100%;height: 100%;background-color: #303840;display: flex;align-items: center;justify-content: center;}
.error-img {max-width: 86px;}
.edit-logo-btn {width: 177px;height: 40px;line-height: 40px;background-color: #3b7eec;color: #ffffff;text-align: center;}
.store-info-form .el-input__inner {background-color: #21282f;border: none;color: #fff;}
.store-info-form .el-textarea__inner {height: 188px;background-color: #21282f;border: none;color: #fff;}
.stor-info-wrap .el-form-item{width: 48%;}
.el_image {width: 177px;height: 177px;max-width: 177px;}
.el_image_2 {width: 100%;height: 230px;}

.h-img {transition:transform .3s ease}
.hover-img li:hover .h-img {transform:scale(1.2)}
  .default-select {background: url(../public/img/ar-down.png) no-repeat 96% center;color: #db1e16;padding-right: 16px;}
  .default-select option {color: #9292a2;}
  .col-green {color: #88d2d1;}
  .col-glass {color: #3fb618;}
  .theme {color: #dd1e16;}

  @media (max-width:1540px) {
    .center-box2 {width: 100%;padding-left: 15px;padding-right: 15px;}
  }

  @media (max-width:1240px) {
    .center-box {width: 100%;padding-left: 15px;padding-right: 15px;}
  }

  @media (max-width:992px) {
    .disp-form .el-upload--picture-card {width: 100px;height: 100px;line-height: 100px;}
    .disp-form .el-upload-list--picture-card .el-upload-list__item {width: 100px;height: 100px;}
    .agree-show p {margin: 20px 0;}
  .step-bj {padding: 25px 15px;}
  .agree-show {font-size: 14px;}
  .has-agree-btn {height: 40px;line-height: 40px;font-size: 12px;padding: 0 15px;}
  .agree-inner {padding-bottom: 40px;}

    .error-img {max-width: 54px;}
    .re_inner_warp_2_web  .el-form-item {width: 100%;}
    .el_image {width: 48%;height: auto;aspect-ratio: 1 / 1;}
  .el_image_2 {width: 100%;height:auto;aspect-ratio: 1140 / 500;}
    .el-load-btn {height: 40px;line-height: 40px;width: 276px;font-size: 16px;}
    .store-info-pd {
      padding-left: 15px;
      padding-right: 15px;
    }
    .busi-region-inner {justify-content: space-between;}
    .web-region .el-form-item__label {float: none;}
    .web-region .busi-region-width-4 {width: 48%;padding: 0;}
    .rt-pd .rt-pd {padding-left: 0;padding-right: 0;}
    .verify-btn {height: 35px;line-height: 35px;width: 108px;}
    .verify-btn-box {margin: 20px 0;}
    .mt30 {margin-top: 20px;}
    .count-table {font-size: 12px;}
    .width-draw-money {font-size: 16px;}
    .width-draw-text {font-size: 12px;margin-top: 5px;}
    .width-step-ul>li {width: 51.5%;margin-top: 1%;}
    .width-step-ul>li + li {margin-left: 0;}
    .width-step-ul>li:nth-of-type(2n+2) {margin-left: -3%;} 
    .send-vari-btn {font-size: 14px;width: 160px;}
    .evalute-table {font-size: 12px;}
    .re_r_form_item {padding-bottom: 20px;}
    .re_inner_pd {padding-left: 0;padding-right: 0;}
    .rt-pd {padding-left: 15px;padding-right: 15px;}
    .regist_save_btn_box {margin: 20px 0 0;}
    .regist_save_btn {height: 40px;line-height: 40px;}
    .md-ul > li a{padding: 20px 25px;font-size: 14px;}
    .main-drop {font-size: 16px;padding: 15px;}
    .uo-cimg {width: 18px;}
    .register_varify_input_box {
        .code-item + .code-item {margin-left: 5px;}
    }
    .res_result_btn + .res_result_btn {margin-left: 15px;}
    .result_img {width: 64px;}
    .re_result_desc{font-size: 14px;margin-bottom: 25px;}
    .re_result_status {font-size: 24px;margin: 15px 0;}
    .re_result_box {padding: 40px 15px 60px;}
    .register_card_img_box {margin-bottom: 20px;}
    .retister_idcar_btn {height: 40px;line-height: 40px;max-width: 100%;}
    .back_btn {height: 40px;line-height: 40px;font-size: 14px;width: 108px;}
    .register_box {padding: 25px 15px;}
    .register_tit {font-size: 20px;}
    .register_info{font-size: 14px;}
    .register_item_pd {padding-left: 15px;padding-right: 15px;}
    .register_item_tit {font-size: 18px;padding-top: 20px;padding-bottom: 15px;}
    .register_iinner {padding-top: 15px;padding-bottom: 25px;}
    .register_warning  {font-size: 12px;}
    .register_check_btn {height: 40px;line-height: 40px;width: 150px;font-size: 14px;}
    .register_varify_email_box {font-size: 14px;padding: 15px;}

    

    .dispute-inner {padding: 20px 15px;}
    .dis-img-box {padding-top: 0px;}
    .dispute-step-ul {margin-top: 25px;}

    .disp-form .el-textarea__inner {height: 140px;}
    .disp-form .avatar-uploader-icon {width: 80px;height: 80px;line-height: 80px;}
    .disp-form .avatar-uploader .el-upload {font-size: 36px;}
    .main-rchoose .el-form {flex-direction: column;align-items: flex-start;}
    .mrc-left .el-form-item {width: 100%;margin-right: 0;}
    .main-rit {font-size: 18px;}
    .main-table th {font-size: 14px;}
    .main-table {font-size: 12px;}
    .main-table th,.main-table td {padding: 10px;}
    .mrc-left {width: 100%;}
    .rt-cimg {width: 18px;}
    .main-rtop {padding-left: 25px;}
    .main-rtop-box {padding-top: 20px;padding-bottom: 20px;}
    .trade-status {font-size: 12px;}
    .user-table th {font-size: 14px;}
    .user-table {font-size: 12px;}
    .user-table th,.user-table td {padding: 15px 10px!important;}
    .busi-proinfo-table th {font-size: 14px;}
    .busi-proinfo-table {font-size: 12px;}
    .min-box-vice-tit {font-size: 14px;margin-bottom:  15px;}
    .agreement {padding: 15px 0;}
    .select-group1{height: 40px;line-height: 40px;width: 125px;padding-left: 15px;}
    .forgot {margin-bottom: 15px;}
    .main-pb {padding-bottom: 40px;}
    .el-form-item {margin-bottom: 20px;}
    .trad-icon-a + .trad-icon-a{margin-left: 5px;}
    .trad-bul1 > li .trad-pro-img {width: 100%;}
    .trad-bott-box {padding-top: 25px;}
    .submit-btn {height: 40px;line-height: 40px;width: 96px;}
    .min-box-tit {font-size: 18px;margin-bottom: 20px;}
    .hide-web {display: none;}
    .hide-pc {display: block;}
    .select-group {height:35px;width:112px;padding-left:15px;}
    .licon-btit {font-size:12px;}
    .licon {width:24px;}
    .login-bul > li {padding:10px 0}
    .other-link + .other-link {margin-left:15px}
    .other-links {padding:15px 0;}
    .form-or {display:none}
    .login-logo {max-width:60px}
    .login-logo-box {display:none}
    .login-tit {font-size:24px;margin-bottom:15px;}
    .login-form .el-form-item {margin-bottom:15px;}
    .login-form {padding:20px 15px 0;}
    .vericode {height:32px;width:108px;}
    .posi-code {height:30px;width:108px;}
    .login-btn {height:35px;line-height:35px;}
    .licon-bdesc {display:none}
    .news_time {font-size:12px;}
    .banner-list-tit {font-size: 24px;}
    .news_ul > li {width:48.5%;margin-left:3%;margin-top:15px;}
    .news_ul > li:nth-of-type(4n+1) {margin-left:3%}
    .news_ul > li:nth-of-type(2n+1) {margin-left:0}
    .news_ul {margin-top: -15px;}
    .news_ {padding-bottom: 40px;}
    .banner-list-img {min-height: 160px;object-fit: cover;}
    .banner-list-desc {font-size: 12px;}
    .banner-list-logo {max-width: 66px;}
    .td-position {font-size: 14px;}
    .trad-bul1 > li .trad-bbr-item, .trad-bul1 > li .trd-pro-stock {margin-top: 10px;}
    .trad-bul1>li .trd-pro-info {margin-top: 20px;margin-left: 0;} 
    .trad-bul1>li {width: 48.5%;margin-left: 3%;margin-top: 3%;}
    .trad-bul1>li:nth-of-type(4n+1) {margin-left: 3%;}
    .trad-bul1>li:nth-of-type(2n+1) {margin-left: 0;}
    .trad-bb-right {margin-top: 10px;}
    .trad-bl-item + .trad-bl-item {margin-top: 5px;}
    .trd-pro-tit {font-size: 14px;}
    .trad-bul:not(.trad-bul1) .trad-pro-img {width: 66px;}
    .trad-bult-left {flex-direction: column;max-width: calc(100% - 30px);}
    .trad-i_tit {-o-text-overflow: ellipsis;text-overflow: ellipsis; overflow: hidden; display: inline-block; white-space: nowrap}
    
    .mr20 {margin-right: 10px;}
    .ml20 {margin-left: 10px;}
    .ml15 {margin-left:5px}
    .mr15 {margin-right:5px}
    .fix-web>[class*=left],.fix-web>[class*=right] {width: 100%;max-width: none;}
    .flex-web {flex-direction: column!important;}
    .flex-web>[class*=left],.flex-web>[class*=right] {width: 100%!important;padding-left: 0;padding-right: 0;}
    .flex-web> .right-mt5 {margin-top: 5px;}
    .flex-web> .right-mt10 {margin-top: 10px;}
    .flex-web> .right-mt15 {margin-top: 15px;}
    .flex-web> .right-mt20 {margin-top: 20px;}
    .flex-web> .right-mt25 {margin-top: 25px;}
    .trad-bul>li {font-size: 12px;}
    .trad-bul-pd {padding: 12px;}
    .unidesc {font-size: 14px;}
    .buy-btn {height: 30px;line-height: 30px;width: 86px;font-size: 14px;margin-right: 0;}
    .el-pagination__jump {margin-left: 0;}
    .el-pagination {padding: 30px 0;white-space:normal;}
  }

  @media (max-width:768px) {
    .trad-bul1 > li .trad-i_tit {max-width: 70px;}
    .flex-phone {flex-direction: column!important;}
    .flex-phone>[class*=left],.flex-phone>[class*=right] {width: 100%!important;padding-left: 0;padding-right: 0;}
    .flex-phone> .right-mt5 {margin-top: 5px;}
    .flex-phone> .right-mt10 {margin-top: 10px;}
    .flex-phone> .right-mt15 {margin-top: 15px;}
    .flex-phone> .right-mt20 {margin-top: 20px;}
    .flex-phone> .right-mt25 {margin-top: 25px;}
  }
</style>
