import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'
import $http from '../utils/http';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta:{topfooter:true},
    component: HomeView
  },
  {
    path: '/tradding',
    name: 'tradding',
    meta:{topfooter:true},
    component: () => import('../views/TraddingFloor.vue')
  },
  {
    path: '/traddingdetail/:id',
    name: 'traddingdetail',
    meta:{topfooter:true},
    component: () => import('../views/TraddingDetail.vue')
  },
  {
    path: '/confirmorder/:id',
    name: 'confirmorder',
    meta:{topfooter:true},
    component: () => import('../views/ConfirmOrder.vue')
  },
  {
    path: '/orderpay/:id',
    name: 'orderpay',
    meta:{topfooter:true},
    component: () => import('../views/OrderPay.vue')
  }
  ,
  {
    path: '/searchresult',
    name: 'searchresult',
    meta:{topfooter:true},
    component: () => import('../views/SearchResult.vue')
  }
  ,
  {
    path: '/aboutus',
    name: 'aboutus',
    meta:{topfooter:true,id:9},
    component: () => import('../views/AboutUs.vue')
  }
  ,
  {
    path: '/help',
    name: 'helpcenter',
    meta:{topfooter:true,id:14},
    component: () => import('../views/HelpCenter.vue')
  }
  ,
  {
    path: '/helpdetails/:id',
    name: 'helpdetails',
    meta:{topfooter:true,id:14},
    component: () => import('../views/HelpDetails.vue')
  }
  ,
  {
    path: '/news',
    name: 'news',
    meta:{topfooter:true,id:17},
    component: () => import('../views/NewsCenter.vue')
  }
  ,
  {
    path: '/newsdetails/:id',
    name: 'newsdetails',
    meta:{topfooter:true,id:17},
    component: () => import('../views/NewsDetails.vue')
  }
  ,
  {
    path: '/contactus',
    name: 'contactus',
    meta:{topfooter:true,id:18},
    component: () => import('../views/ContactUs.vue')
  }
  ,
  {
    path: '/businesshome',
    name: 'businesshome',
    meta:{topfooter:true,id:18},
    component: () => import('../views/BusinessHome.vue')
  },
  {
    path: '/user/login',
    name: 'u_login',
    meta:{topfooter:false},
    component: () => import('../views/User/LoginPage.vue')
  }
  ,
  {
    path: '/user/forgot',
    name: 'u_forgot',
    meta:{topfooter:false},
    component: () => import('../views/User/ForgotPass.vue')
  }
  ,
  {
    path: '/user/privacy',
    name: 'u_privacy',
    meta:{topfooter:true},
    component: () => import('../views/User/PrivacyPolicy.vue')
  }
  ,
  {
    path: '/user/terms',
    name: 'u_terms',
    meta:{topfooter:true},
    component: () => import('../views/User/TermsOfUse.vue')
  }
  ,
  {
    path: '/user/register',
    name: 'u_register',
    meta:{topfooter:false},
    component: () => import('../views/User/RegisterPage.vue')
  }
  ,
  {
    path: '/user/usercenter',
    name: 'u_usercenter',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/UserCenter.vue')
  }
  ,
  {
    path: '/user/orderlist',
    name: 'u_orderlist',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/OrderList.vue')
  }
  ,
  {
    path: '/user/orderdetails/:id',
    name: 'u_orderdetails',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/OrderDetails.vue')
  }
  ,
  {
    path: '/user/disputelist',
    name: 'u_disputelist',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/DisputeList.vue')
  }
  ,
  {
    path: '/user/disputeapply',
    name: 'u_disputeapply',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/DisputeApply.vue')
  }
  ,
  {
    path: '/user/disputeprogress',
    name: 'u_disputeprogress',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/DisputeProgress.vue')
  }
  ,
  {
    path: '/user/agreement',
    name: 'u_agreement',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerAgreement.vue')
  }
  ,
  {
    path: '/user/shopname',
    name: 'u_shopname',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerShopName.vue')
  }
  ,
  {
    path: '/user/emailvarify',
    name: 'u_emailvarify',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerEmailVarify.vue')
  }
  ,
  {
    path: '/user/identifyvarify',
    name: 'u_identifyvarify',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerIdentifyVarify.vue')
  }
  ,
  {
    path: '/user/applycate',
    name: 'u_applycate',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerApplyCate.vue')
  },
  {
    path: '/user/result',
    name: 'u_result',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerResult.vue')
  }
  ,
  {
    path: '/user/accountinfo',
    name: 'u_accountinfo',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/AccountInfo.vue')
  }
  ,
  {
    path: '/user/secretsetting',
    name: 'u_secretsetting',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/BecomeSellerSecretSetting.vue')
  }
  ,
  {
    path: '/user/uservarify',
    name: 'u_uservarify',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/UserVarify.vue')
  }
  ,
  {
    path: '/user/announcement',
    name: 'u_announcement',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/AnnounceMent.vue')
  }
  ,
  {
    path: '/user/announdetails/:id',
    name: 'u_announdetails',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/AnnounceMentDetails.vue')
  }
  ,
  {
    path: '/user/logoffaccount',
    name: 'u_logoffaccount',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/LogoffAccount.vue')
  }
  ,
  {
    path: '/user/commentlist',
    name: 'u_commentlist',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/CommentList.vue')
  }
  ,
  {
    path: '/user/evaluate',
    name: 'u_evaluate',
    meta:{topfooter:true,requireAuth:true},
    component: () => import('../views/User/EvaluatePage.vue')
  }
  ,
  {
    path: '/business/businesshome',
    name: 'b_businesshome',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/BusinessHome.vue')
  }
  ,
  {
    path: '/business/securitydeposit',
    name: 'b_securitydeposit',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/SecurityDeposit.vue')
  }
  ,
  {
    path: '/business/storeinfo',
    name: 'b_storeinfo',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/StoreInfo.vue')
  }
  ,
  {
    path: '/business/disputeapply',
    name: 'b_disputeapply',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/DisputeApply.vue')
  }
  ,
  {
    path: '/business/orderlist',
    name: 'b_orderlist',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/OrderList.vue')
  }
  ,
  {
    path: '/business/disputelist',
    name: 'b_disputelist',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/DisputeList.vue')
  }
  ,
  {
    path: '/business/orderdetails/:id',
    name: 'b_orderdetails',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/OrderDetails.vue')
  }
  ,
  {
    path: '/business/voucher/:id',
    name: 'b_voucher',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/Voucher.vue')
  }
  ,
  {
    path: '/business/disprogress',
    name: 'b_disprogress',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/DisputeProgress.vue')
  }
  ,
  {
    path: '/business/incomedetails',
    name: 'b_incomedetails',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/IncomeDetails.vue')
  }
  ,
  {
    path: '/business/evaluatedetails',
    name: 'b_evaluatedetails',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/EvaluateDetails.vue')
  }
  ,
  {
    path: '/business/evalutelist',
    name: 'b_evalutelist',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/EvaluteList.vue')
  }
  ,
  {
    path: '/business/addbank',
    name: 'b_addbank',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/AddBank.vue')
  }
  ,
  {
    path: '/business/bindcount',
    name: 'b_bindcount',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/BindCount.vue')
  }
  ,
  {
    path: '/business/widthdrawresult',
    name: 'b_widthdrawresult',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/WidthdrawResult.vue')
  }
  ,
  {
    path: '/business/widthdrawstep',
    name: 'b_widthdrawstep',

    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/WidthdrawStep.vue')
  }
  ,
  {
    path: '/business/widthdrawstep2',
    name: 'b_widthdrawstep2',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/WidthdrawStep2.vue')
  }
  ,
  {
    path: '/business/widthdrawstep3',
    name: 'b_widthdrawstep3',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/WidthdrawStep3.vue')
  }
  ,
  {
    path: '/business/managementlist',
    name: 'b_managementlist',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/ManagementList.vue')
  }
  ,
  {
    path: '/business/creatlist',
    name: 'b_creatlist',
    meta:{topfooter:true,requireAuth:true,identify:true},
    component: () => import('../views/Business/CreatList.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next)=>{
  
  $http.post("/api/common/get_web_seo",{ty:to.meta.id}).then((res)=>{
    if (res.code == 200) {
      document.title = res.data.title
      document.querySelector('meta[name="keywords"]')?.setAttribute('content',res.data.keyword)
      document.querySelector('meta[name="description"]')?.setAttribute('content',res.data.description)
    }
  })

  if (to.meta.requireAuth) {
    // 如果该路由需要用户登录，则检查用户是否已经登录
    if (store.getters.getToken) {
      // 如果用户已经登录，则允许访问该路由

      if (to.meta.identify) {
        if (store.getters.getIdentify >= 3) {
          next()
        } else {
          next({path:'/user/usercenter'})
        }
      }
      next()
    } else {
      // 如果用户没有登录，则重定向到登录页面
      next({
        path: '/user/login'
      })
    }
  } else {
    // 如果该路由不需要用户登录，则直接访问
    next()
  }
})


router.afterEach(()=>{
  window,scrollTo(0,0)
})

export default router
