import axios from 'axios';
import store from '../store/index'
import { Message,Loading } from 'element-ui';
import _ from 'lodash';
import router from '@/router';

let loading;

export const $http = axios.create({
  // baseURL: "/api",
  baseURL: "https://sptgamer.com/",
  withCredentials: true,
  timeout: 10000, // 请求超时时间
})

function showLoading() {
  if (!loading) {
    loading = Loading.service({
      lock: true,
      text: "Loading...",
    });
  }
}

//隐藏loading
function hideLoading() {
  toHideLoading();
}

var toHideLoading = _.debounce(()=>{
  if (loading) {
    loading.close();
    loading = null;
  }
}, 300);


//第三步 设置请求拦截

$http.interceptors.request.use(config=>{
  if(config.headers.showLoading == 'true'){
    showLoading();
  }
  const token = store.getters.getToken;
  if (token) {
    // 已经登录成功，统一添加token
    config.data = {...config.data,token}
  }
  return config
},err=>{
  if(err.config.headers.showLoading == 'true'){
    hideLoading();
  }
  Message.error('请求超时!');
})

//第四步 设置响应拦截
$http.interceptors.response.use(response=>{
      //判断状态码
      if(response.config.headers.showLoading == 'true'){
        hideLoading();
      }
      if (!response.config.headers.noAuth) {
        if (response.data.code == 203) {
          store.commit('delToken')
          Message.warning("请先登录！")
          router.replace({path:'/user/login'})
        }
      }
      return response.data
  },err=>{
      if(err.config.headers.showLoading == 'true'){
        hideLoading();
      }
      console.log(err)
      // return Promise.reject(err)
  })
  
  //导出  导出这个模块
  export default $http
  

