<template>
  <div class="head head-fixed">
    <div class="head-top">
      <div class="center-box flex-row align-center space-between flex-phone">
        <div class="ht-left ht-safe flex-row align-center">
          <i class="iconfont icon-anquan"></i>
            <div class="ht-info ml20">
              <div class="ht-tit">SAFE & SECURE</div>
              <div class="ht-desc">100% SECURE AND 24H SUPPORT</div>
            </div>
        </div>
        <div class="ht-right flex-row align-center right-mt5 space-between">
          <div class="htr-left flex-row align-center">
            <!-- <select class="normal-select money-select mr20">
              <option value="USD">USD</option>
            </select> -->
            <div class="select-group flex-row align-center mr20">
              <img class="cou-img mr5" src="../../public/img/en.png" alt="">
              <select class="normal-select country-select">
                <option value="English">English</option>
              </select>
            </div>
          </div>
          <div class="login-box flex-row align-center">
            <template v-if="$store.getters.getToken && expired">
              <router-link :to="{path:'/user/usercenter'}">
                <i class="iconfont icon-set mr10"></i>
              </router-link>
              <router-link :to="{path:'/user/usercenter'}">
                <el-avatar class="avatar_head" :src="head_img">
                  <img src="../../public/img/avater-error.png"/>
                </el-avatar>
              </router-link>
              <a class="login-links ml5" href="javascript:void(0)" @click="_logout">logout</a>
            </template>
            <template v-else>
              <router-link class="login-links" :to="{path:'/user/register'}"><i class="iconfont icon-yonghu mr5"></i>Register</router-link>
              <router-link class="login-links ml15" :to="{path:'/user/login'}"><i class="iconfont icon-lock mr5"></i>Login</router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="head-bott">
      <div class="center-box flex-row align-center space-between flex-wrap">
        <div class="head-left">
          <router-link to="/">
            <img class="logo-img" :src="wab_info.logo" alt="">
          </router-link>
        </div>
        <div class="head-mid">
          <div class="head-search flex-row align-center head-normal-place">
            <input class="head-val flex1" type="text" v-model="keyword" placeholder="Search" @keyup.enter="_topage" />
            <button class="btn-" @click="_topage"><i class="iconfont icon-sousuo"></i></button>
          </div>
        </div>
        <div class="head-right flex-row align-center">
          <router-link class="shop-links on" to="/tradding">
            <div class="sl-top">
              <i class="iconfont icon-goumaicantuan"></i>
            </div>
            <div class="sl-bott">
              How to buy
            </div>
          </router-link>
          <router-link class="shop-links" v-if="$store.getters.getToken && expired && user_info.is_enter != 1" to="/user/agreement">
            <div class="sl-top">
              <i class="iconfont icon-chushou"></i>
            </div>
            <div class="sl-bott">
              sell at jypt
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadTop',
  data () {
    return {
        wab_info:{},
        head_img:"",
        keyword:"",
        expired:true,
        user_info:{}
    }
  },
  mounted () {
    this.get_wab_info()
    this.get_user_info()
  },
  watch:{
    $route(to) {
      if (to.name != 'searchresult') {
        this.keyword = ""
      }
    }
  },
  methods: {
    _logout(){
      this.$confirm('Are you sure want to logout?', 'warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$store.commit('delToken')
          this.$message.success("logout success!")
          this.$router.replace({path:'/'})
        }).catch((error)=>{
          console.log(error)
        })
    },
    _topage(){
      if (this.$route.name != 'searchresult') {
        this.$router.push({path:'/searchresult',query:{keyword:this.keyword}})
      } else {
        this.$router.replace({path:'/searchresult',query:{keyword:this.keyword}})
      }
    },
    get_wab_info(){
        this.$http.post("/api/common/get_wab_info").then((res)=>{
          if (res.code == 200) {
            this.wab_info = res.data
          }
      })
    },
    get_user_info(){
      this.$http.post("/api/user/get_user_info",{},{headers:{noAuth:true}}).then((res)=>{
          if (res.code == 200) {
            this.user_info = res.data;
            this.head_img = res.data.head_img
          } else if (res.code == 203) {
            this.expired = false
          }
      })
    }
  }
}
</script>
<style scoped lang="scss">
input[type="text"],button {
    border: none;
    outline: none;
    background-color: transparent;
}
.head-normal-place ::-webkit-input-placeholder{
  font-size: 18px;
  color: #8c8c8c;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.head-normal-place ::-moz-placeholder{
  font-size: 18px;
  color: #8c8c8c;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.head-normal-place :-ms-input-placeholder{
  font-size: 18px;
  color: #8c8c8c;
  font-family:"GOTHIC","PingFang",Microsoft YaHei,Arial,sans-serif,'Times New Roman', Times, serif;
}
.head-left {max-width: 151px;}
.head-top {padding: 5px 0;background-color: #fff;font-size: 12px;}
.head-fixed {position: sticky;position: -webkit-sticky;top: 0;z-index: 99;}
.ht-safe {width: 293px;padding: 5px 20px;border: 1px solid #a6a6a6;}
.ht-safe .iconfont {font-size: 26px;color: #1dd395;}
.ht-tit {font-size: 14px;color: #1dd395;}
.money-select {background: url(../../public/img/arrow-down.png) no-repeat 96% center;padding-right: 16px;text-align: center;color: #333;font-size: 12px;}
.select-group {width: 100px;height: 26px;line-height: 26px;border: 1px solid #7d7d7d;padding: 0 10px;}
.country-select {background: url(../../public/img/arrow-down-fill.png) no-repeat 96% center;padding-right: 16px;text-align: center;color: #333;font-size: 12px;}
.head-bott {background-color: #212121;color: #fff;padding: 25px 0 20px;}
.head-search {width: 495px;height: 42px;line-height: 42px;background-color: #fff;border-radius:20px;padding: 0 20px;}
.head-val {font-size: 18px;color: #666;width: 100%;}
.shop-links {display: inline-block;width: 88px;height: 60px;border-radius: 10px;text-align: center;border: 1px solid #bfbfbf;font-size: 12px;text-transform: uppercase;display: flex;flex-direction: column;justify-content: center;align-items: center;transition: all .3s ease;color: #bfbfbf;}
.shop-links + .shop-links {margin-left:20px;}
.shop-links.on{background: linear-gradient(to right,#c51816,#ff3100);border-color: #ff3100;color: #fff;}
.shop-links .iconfont {font-size: 20px;}
.head-search .iconfont {font-size: 18px;}
.sl-bott {margin-top: 5px;}
.login-links:hover {color: #dd1e16;}
.icon-set {font-size: 24px;}
@media (max-width:992px) {
  .head-normal-place ::-webkit-input-placeholder{
    font-size: 16px;
  }
  .head-normal-place ::-moz-placeholder{
    font-size: 16px;
  }
  .head-normal-place :-ms-input-placeholder{
    font-size: 16px;
  }
  .head-val {font-size:16px;}
  .head-left {order:1;}
  .head-right {order:2;}
  .head-mid {order:3;width:100%;margin-top:10px;}
  .head-search {width:100%;height:36px;line-height:36px;padding:0 15px;}
  .sl-bott {display:none}
  .shop-links {width:42px;height:30px;border-radius:4px;}
  .shop-links + .shop-links {margin-left:5px;}
  .head-bott {padding:15px 0;}
  .shop-links .iconfont {font-size:16px;}
  .ht-safe {padding:5px 15px;}
}

@media (max-width:640px) {
  .head-left {max-width: 24%;}
}
</style>
<style lang="scss">
.avatar_head {width: 32px;height: 32px;}
</style>
